const getStateCodeFromState = function (state) {
  switch (state) {
    case "Arizona":
      return "AZ";
    case "Idaho":
      return "ID";
    case "Nevada":
      return "NV";
    case "New Mexico":
      return "NM";
    case "NewMexico":
      return "NM";
    case "Oregon":
      return "OR";
    case "Texas":
      return "TX";
    case "Utah":
      return "UT";
    case "Washington":
      return "WA";
    default:
      return null;
  }
};

export default getStateCodeFromState;
