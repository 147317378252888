import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import CommunityBankingOfficerSummary from "../../components/community-banking-officer-summary";
import List from "../../components/custom-widgets/list";

function getStateOfficers(officers, stateCode) {
  return officers.filter((officer) => officer.RegionAndDivision.some((region) => region.State === stateCode));
}

const NearMe = () => {
  const pageData = useStaticQuery(graphql`
    query {
      officers: allStrapiCommunityBankingOfficers {
        nodes {
          Name
          RegionAndDivision {
            State
          }
          Address {
            BranchGeoLocation {
              Lat
              Lng
            }
            City
            Country
            State
            StreetAddress
            ZipCode
          }
          MSBookingsBranchName
          EmailAddress
          PhoneNumber
          ProfilePhoto {
            childImageSharp {
              gatsbyImageData
            }
          }
          id
        }
      }
    }
  `);

  const AZOfficers = getStateOfficers(pageData.officers.nodes, "AZ");
  const IDOfficers = getStateOfficers(pageData.officers.nodes, "ID");
  const NVOfficers = getStateOfficers(pageData.officers.nodes, "NV");
  const NMOfficers = getStateOfficers(pageData.officers.nodes, "NM");
  const OROfficers = getStateOfficers(pageData.officers.nodes, "OR");
  const TXOfficers = getStateOfficers(pageData.officers.nodes, "TX");
  const UTOfficers = getStateOfficers(pageData.officers.nodes, "UT");
  const WAOfficers = getStateOfficers(pageData.officers.nodes, "WA");

  const seoData = {
    title: "Small Business Banking Services",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Small Business Banking Services"
      },
      {
        name: "description",
        content:
          "WaFd Bank's small business banking team is here to provide your business with the guidance it needs to succeed. Learn more and contact us today."
      },
      {
        property: "og:title",
        content: "Small Business Banking Services"
      },
      {
        property: "og:description",
        content:
          "WaFd Bank's small business banking team is here to provide your business with the guidance it needs to succeed. Learn more and contact us today."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/near-me"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-small-business-rates-01-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking/small-business",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Find a Banking Officer"
    }
  ];

  const officerData = [
    {
      id: 1,
      region: "Arizona",
      officers: AZOfficers
    },
    {
      id: 2,
      region: "Idaho",
      officers: IDOfficers
    },
    {
      id: 3,
      region: "Nevada",
      officers: NVOfficers
    },
    {
      id: 4,
      region: "New Mexico",
      officers: NMOfficers
    },
    {
      id: 5,
      region: "Oregon",
      officers: OROfficers
    },
    {
      id: 6,
      region: "Texas",
      officers: TXOfficers
    },
    {
      id: 7,
      region: "Utah",
      officers: UTOfficers
    },
    {
      id: 8,
      region: "Washington",
      officers: WAOfficers
    }
  ];

  const lendingPlatformsListData = {
    accountName: "lending-platform",
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Unsecured Lines of Credit"
      },
      {
        id: 2,
        text: "Secured Lines of Credit"
      },
      {
        id: 3,
        text: "Term Loans"
      },
      {
        id: 4,
        text: "Commercial Real Estate Lines of Credit"
      },
      {
        id: 5,
        text: "Commercial Real Estate Term Loans"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container">
        <h1>Small Business Banking Team</h1>
        <h4>
          Speak with a Community Banker in your local market about different lending options available at WaFd Bank. We
          offer loans and lines of credit that are customized to your business needs. Our bankers take the time to
          understand your business, its cash flow and opportunities to leverage financing to position yourself best for
          success. We offer the following lending platforms:
        </h4>
        <List {...lendingPlatformsListData} />

        {officerData.map((data, idx) => {
          return (
            <>
              {data.officers.length >= 1 && (
                <div className={idx + 1 === officerData.length ? "py-5" : "pt-5 border-bottom"}>
                  <h2 id={`${data.region}`}>{data.region}</h2>

                  <div className="row">
                    {data.officers.map((officer, idx) => {
                      const classes = idx % 2 !== 0 ? "border-md-left pl-md-5" : null;
                      return (
                        <div className={`col-md-6 ml-5 ml-md-0 mb-5 ${classes}`}>
                          <CommunityBankingOfficerSummary communityBankingOfficer={officer} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          );
        })}
      </section>
    </SecondaryLanding>
  );
};

export default NearMe;
