import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Icon from "../components/custom-widgets/icon";
import getViewOnGoogleMapsUrl from "../helpers/getViewOnGoogleMapsUrl";
import getStateCodeFromState from "../helpers/getStateCodeFromState";
import isWaFdBankInState from "../helpers/isWaFdBankInState";
import BankerHeadshot from '../components/banker-headshot'

const CommunityBankingOfficerSummary = (props) => {
  const stateCode = isWaFdBankInState(props.communityBankingOfficer.Address.State)
    ? props.communityBankingOfficer.Address.State
    : getStateCodeFromState(props.communityBankingOfficer.Address.State);

  const state = `${props.communityBankingOfficer.RegionAndDivision[0].State}`;

  const bankerName = props.communityBankingOfficer.Name.replace(/ +/g, "-").toLowerCase();

  return (
    <div>
      <h3 className="mb-2" id={`${bankerName}-${state}-name`}>
        {props.communityBankingOfficer.Name}
      </h3>
      <div className="row mb-4">
        <div className="col-12 col-lg-3 pl-lg-1 pr-lg-0">
          <div className="text-md-center">
            <BankerHeadshot email={props.communityBankingOfficer.EmailAddress} style={{marginTop: "0.25rem"}} aria-label={`WaFd Bank Community Banking Officer ${props.communityBankingOfficer.Name}`}/>
          </div>
        </div>
        <div className={`col-12 col-lg-9 pl-lg-0`}>
          <div className="mb-1">{props.communityBankingOfficer.RegionAndDivision.Division}</div>
          <div className="mb-1">
            <Icon lib="fal" name="map-marker-alt" fixedWidth="1.75rem" containerClass="mr-2 float-left" />{" "}
            <a
              href={getViewOnGoogleMapsUrl(props.communityBankingOfficer)}
              target="_blank"
              rel="noopener noreferrer"
              className="d-block overflow-hidden text-decoration-none"
              id={`${bankerName}-${state}-address`}
            >
              {props.communityBankingOfficer.Address?.StreetAddress}
              <br />
              {props.communityBankingOfficer.Address?.City}, {stateCode}{" "}
              {props.communityBankingOfficer.Address?.ZipCode}
            </a>
          </div>
          <div className="mb-1">
            <Icon lib="fal" name="phone-alt" fixedWidth="1.75rem" containerClass="mr-2 float-left" />{" "}
            <a
              id={`${bankerName}-${state}-phone`}
              href={`tel:${props.communityBankingOfficer.PhoneNumber}`}
              className="d-block overflow-hidden text-decoration-none"
            >
              {props.communityBankingOfficer.PhoneNumber}
            </a>
          </div>
          <div className="mb-1">
            <Icon lib="fal" name="envelope-open-text" fixedWidth="1.75rem" containerClass="mr-2 float-left" />
            <a
              id={`${bankerName}-${state}-email`}
              href={`mailto:${props.communityBankingOfficer.EmailAddress}`}
              className="d-block overflow-hidden text-decoration-none"
            >
              {props.communityBankingOfficer.EmailAddress}
            </a>
          </div>
        </div>
      </div>
      {props.communityBankingOfficer.MSBookingsBranchName ? (
        <div className="row">
          <div className="col-sm-6">
            <Link
              to={"/make-an-appointment?bankerName=" + props.communityBankingOfficer.MSBookingsBranchName}
              className="btn btn-primary mb-3"
            >
              Make an Appointment
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};

CommunityBankingOfficerSummary.defaultProps = {
  communityBankingOfficer: {
    id: null,
    EmailAddress: null,
    Name: null,
    PhoneNumber: null,
    RegionAndDivision: [
      {
        Division: null
      }
    ],
    ProfilePhoto: {
      childImageSharp: null
    },
    Address: {
      BranchGeoLocation: {
        Lat: null,
        Lng: null
      },
      City: null,
      Country: null,
      State: null,
      StreetAddress: null,
      ZipCode: null
    },
    MSBookingsBranchName: null,
    Distance: null
  }
};

export default CommunityBankingOfficerSummary;
